const { numberFormat } = require("highcharts");

document.addEventListener("turbolinks:load", function() {
    $('.bottom-user-table-title').each(function(){
        $(this).click(function(){
            if($(this).children('svg').hasClass('fa-chevron-down')){
                $(this).next().slideDown();
                $(this).children('svg').removeClass('fa-chevron-down');
                $(this).children('svg').addClass('fa-chevron-up');
            }else{
                $(this).next().slideUp();
                $(this).children('svg').removeClass('fa-chevron-up');
                $(this).children('svg').addClass('fa-chevron-down');
            }
        });
    });
    // 年齢別割合
    Highcharts.chart({
        colors: ["#F54200", "#FD8039", "#29B8C0", "#81DCE2", "#707070", "#D3D3D3"],
        chart: {
            renderTo: 'userAgeRate',
            // どんなタイプのグラフを表示させるか
            type: 'pie'
        },
        title: {
            // グラフのタイトル
            text: ''
        },
        plotOptions: {
            pie: {
                // 円グラフの内側のサイズ
                innerSize: '70%'
            }
        },
        // 表示させるデータ
        series: [
            {
                name: 'ユーザー数',
                dataLabels:
                    {
                        enabled:false
                    },
                data: gon.userAge
            }
        ],
        exporting: { 
            enabled: false 
        },
        credits: {
            enabled: false,
        }
    });

    //デバイス別
    var chart = Highcharts.chart({
        colors: ["#FD8039", "#29B8C0", "#81DCE2"],
        chart: {
            renderTo: 'userDeviceRate',
            // どんなタイプのグラフを表示させるか
            type: 'pie'
        },
        title: {
            // グラフのタイトル
            text: ''
        },
        plotOptions: {
            pie: {
                // 円グラフの内側のサイズ
                innerSize: '70%'
            }
        },
        // 表示させるデータ
        series: [
            {
                name: 'ユーザー数',
                dataLabels:
                    {
                        enabled:false
                    },
                data: [
                    ['モバイル', gon.userDevice[0][1]],
                    ['PC', gon.userDevice[1][1]],
                    ['タブレット', gon.userDevice[2][1]]
                ]
            }
        ],
        exporting: { 
            enabled: false 
        },
        credits: {
            enabled: false,
        }
    });

    //性別の割合
    Highcharts.chart({
        colors: ["#29B8C0", "#FD8039"],
        chart: {
            renderTo: 'userGenderRate',
            // どんなタイプのグラフを表示させるか
            type: 'pie'
        },
        title: {
            // グラフのタイトル
            text: ''
        },
        plotOptions: {
            pie: {
                // 円グラフの内側のサイズ
                innerSize: '70%'
            }
        },
        // 表示させるデータ
        series: [
            {
                name: 'ユーザー数',
                dataLabels:
                    {
                        enabled:false
                    },
                data: [
                    ['男性', gon.userGenger[0][1]],
                    ['女性', gon.userGenger[1][1]]
                ]
            }
        ],
        exporting: { 
            enabled: false 
        },
        credits: {
            enabled: false,
        }
    });

    //新規とリピーター
    Highcharts.chart({
        colors: ["#FD8039", "#29B8C0"],
        chart: {
            renderTo: 'userJudgeRate',
            // どんなタイプのグラフを表示させるか
            type: 'pie'
        },
        title: {
            // グラフのタイトル
            text: ''
        },
        plotOptions: {
            pie: {
                // 円グラフの内側のサイズ
                innerSize: '70%'
            }
        },
        // 表示させるデータ
        series: [
            {
                name: 'ユーザー数',
                dataLabels:
                    {
                        enabled:false
                    },
                data: [
                    ['新規', gon.userType[0][1]],
                    ['リピーター', gon.userType[1][1]]
                ]
            }
        ],
        exporting: { 
            enabled: false 
        },
        credits: {
            enabled: false,
        }
    });

    let user_region = [];
    let num = 0;
    while(num < gon.userRegion.length){
        if(gon.userRegion[num][1] != 0){
            user_region.push(gon.userRegion[num]);
        }
        num++;
    }

    google.load("visualization", "1", {packages:["geochart"]});
    google.setOnLoadCallback(drawRegionsMap);

    function drawRegionsMap() {

        var data = google.visualization.arrayToDataTable(user_region);

        var options = {
            region: 'JP',
            resolution: 'provinces',
            colorAxis: {colors: ['skyblue','blue']}
        };

        var chart = new google.visualization.GeoChart(document.getElementById('regions_div'));

        chart.draw(data, options);
    }
    
    function judgeHeight(a, b, c){
        if($('.japan-map table').height() > a){
            $('.japan-map-contents').height(c);
        }else{
            $('.japan-map-contents').height(b);
        }
    }

    var width = window.innerWidth;
    $(window).on("orientationchange", function(){
        if(width > 1024){
            if(window.orientation === 0){
                if(window.innerWidth > 768){
                    $('#regions_div svg').css({'transform': 'scale(0.72)', 'transformOrigin': 'top left'});
                    judgeHeight(280, 140, $('.japan-map table').height());
                }
            }else if(window.orientation === 90){
                if(window.innerWidth > 768){
                    $('#regions_div svg').css({'transform': 'scale(1.0)', 'transformOrigin': 'top left'})
                    judgeHeight(280, 220, $('.japan-map table').height());
                }
            }
        }else if(width <= 414){
            if(window.orientation === 0){
                if(window.innerWidth > 414){
                    $('#regions_div svg').css({'transform': 'scale(1.0)', 'transformOrigin': 'top left'})
                }
            }else if(window.orientation === 90){
                if(window.innerWidth <= 414){
                    $('#regions_div svg').css({'transform': 'scale(1.0)', 'transformOrigin': 'top left'})
                }
            }
        }else if(width <= 768){
            if(window.orientation === 0){
                if(window.innerWidth <= 768){
                    $('#regions_div svg').css({'transform': 'scale(0.8)', 'transformOrigin': 'top left'})
                }else if(window.innerWidth > 768){
                    $('#regions_div svg').css({'transform': 'scale(1.0)', 'transformOrigin': 'top left'})
                    judgeHeight(310, $('.japan-map table').height() + 290, $('.japan-map table').height() + 260)
                    
                }
            }else if(window.orientation === 90){
                if(window.innerWidth <= 414){
                    $('#regions_div svg').css({'transform': 'scale(1.0)', 'transformOrigin': 'top left'})
                }else if(window.innerWidth <= 768){
                    $('#regions_div svg').css({'transform': 'scale(0.7)', 'transformOrigin': 'top left'})
                    judgeHeight(310, 200, $('.japan-map table').height() + 30)
                }
            }
        }else if(width <= 1024){
            if(window.orientation === 0){
                if(window.innerWidth >1024){
                    $('#regions_div svg').css({'transform': 'scale(1.0)', 'transformOrigin': 'top left'})
                    judgeHeight(220, 150, $('.japan-map table').height());
                }else if(window.innerWidth > 768){
                    $('.japan-map-contents').height($('.japan-map table').height() + 180)
                }
            }else if(window.orientation === 90){
                if(window.innerWidth > 768){
                    $('#regions_div svg').css({'transform': 'scale(1.3)', 'transformOrigin': 'top left'})
                    judgeHeight(220, 190, $('.japan-map table').height());
                }else if(window.innerWidth > 414){
                    judgeHeight(220, 200, $('.japan-map table').height() + 30);
                }
            }
        }
    });
});
