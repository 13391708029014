document.addEventListener("turbolinks:load", function() {
    //棒グラフ
    var plan_stick_chart = Highcharts.chart({
        colors: ["#0F648F", "#F54200"],
        chart: {
            backgroundColor:'#fff',
            renderTo: 'planStickGraph',
            // どんなタイプのグラフを表示させるか
            type: 'column'
        },
        title: {
            // グラフのタイトル
            text: ''
        },
        //x軸の設定
        xAxis: {
            title: {
                text: ''
            },
            labels: {
                rotation: 0,
                staggerLines: 2,
                style: {
                    whiteSpace: 'nowrap'
                }
            },
            //x軸に表示するデータを設定
            categories: gon.planName,
            //小数点刻みにしない
            allowDecimals: false,
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        exporting: {
            enabled: false
        },
        legend: {
            enabled: false,
            //凡例が縦に並ぶ
            layout: 'vertical',
            //凡例の横位置
            align: 'right',
            //凡例の縦位置
            verticalAlign: 'top'
        },
        // 表示させるデータ
        series: [
            {
                name: 'PV数',
                data: gon.pvByPlanForStick
            },
            {
                name: '応募数',
                data: gon.applyByPlanForStick
            }
        ],
        credits:
            {
                enabled: false,
            }
    });
    
    // 年齢別割合
    Highcharts.chart({
        colors: gon.colorArray,
        chart: {
            renderTo: 'planAppCircle',
            // どんなタイプのグラフを表示させるか
            type: 'pie'
        },
        title: {
            // グラフのタイトル
            text: ''
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    color: 'black',
                    formatter: function() {
                        return '<b>'+ this.point.name +'</b>'+'<br>'+ Math.round(this.percentage*10)/10 +'%';
                    },
                    enabled: true,
                    softConnector: false,
                    distance: 5
                }
            }
        },
        exporting: {
            enabled: false
        },
        // 表示させるデータ
        series: [
            {
                name: '応募数',
                data: gon.applyByPlanForCircle
            }
        ],
        credits:
            {
                enabled: false,
            }
    });

    Highcharts.chart({
        colors: gon.colorArray,
        chart: {
            renderTo: 'planPvCircle',
            // どんなタイプのグラフを表示させるか
            type: 'pie'
        },
        title: {
            // グラフのタイトル
            text: ''
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    color: 'black',
                    formatter: function() {
                        return '<b>'+ this.point.name +'</b>'+'<br>'+ Math.round(this.percentage*10)/10 +'%';
                    },
                    enabled: true,
                    softConnector: false,
                    distance: 5
                }
            }
        },
        exporting: {
            enabled: false
        },
        // 表示させるデータ
        series: [
            {
                name: 'PV数',
                data: gon.pvByPlanForCircle
            }
        ],
        credits:
            {
                enabled: false,
            }
    });

});
